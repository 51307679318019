import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
const ShopifyUsersList = () => {
  const data = useStaticQuery(graphql`
    {
      allShopifyUsersListJson {
        nodes {
          name
          url
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  `)

  const {
    allShopifyUsersListJson: { nodes: users },
  } = data

  return (
    <div className="shopify-user-list-grid">
      {users.map((user, index) => {
        return (
          <div key={index} className="user-card">
            <a href={user.url} target="_blank" rel="noreferrer">
              <GatsbyImage
                image={getImage(user.image)}
                alt={user.name}
                className="img"
              />
              <span>{user.name}</span>
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default ShopifyUsersList
