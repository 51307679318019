import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ShopifyUsersList from "../../components/ShopifyUsersList"

import { Parallax } from "react-parallax"
import bgImage from "../../assets/images/What-is-Shopify.jpg"
import { Link } from "gatsby"

const AboutShopify = () => {
  return (
    <>
      <Parallax
        bgImage={bgImage}
        bgImageAlt="shopify"
        strength={400}
        blur={{ min: 0, max: 1 }}
      >
        <div className="parallax-box">
          <div className="parallax-info">
            <h1>About Shopify</h1>
            <h2>Shopifyで自社オンラインストアを作ろう。</h2>
            <p>
              Shopifyとは世界で最も多く使われ評価を得ている。自社オンラインストア構築のためのプラットフォームです。
            </p>
            <div>
              <Link to="/contact" className="btn-primary">
                お問合せ
              </Link>
            </div>
          </div>
        </div>
      </Parallax>

      <section>
        <h1>Shopifyとは？</h1>
        <p>
          今現在日本にはオンラインストア構築のためのプラットフォームが多数あります。それらはマーケットプレイス型と自社EC型にわけることができます。
        </p>
        <h2>マーケットプレイス型</h2>
        <p>
          マーケットプレイスはアマゾンや楽天市場のようなサイトで、プラットフォーム販売者(マーチャント)が出店料や手数料を支払い出店し販売する場合です。
        </p>
        <h3>メリット</h3>
        <p>
          マーケットプレイスを使用する最大のメリットはマーケットプレイスではすでにお客さんが多いプラットフォームなので集客が楽な場合が多いです。
        </p>
        <h3>デメリット</h3>
        <p>
          デメリットは出店料や中間マージンなどが高く、せっかくたくさん売れても利益のほとんどをマーケットプレイスに持っていかれる。またプラットフォーム側からの制約が大きいことあります。例えば近年楽天市場は出店者に対して送料無料にすることを強制したりと問題になったことがあります。
        </p>
        <p>
          さらに、アマゾン・楽天市場には莫大な数の出店者がいます。結局は価格競争になることになります。もともと中間マージンを高くとられるのに価格競争になって売れても利益がほとんどでないなんて話も。
        </p>
        <p>
          また、お客様は『アマゾン』『楽天市場』から買ったことは覚えているが、『どこの出品者』買ったかは覚えられにくいという点です。そうすると、あなたのお店のファンやリピーターを得るのが難しいということも起こります。
        </p>
        <p>
          マーケットプレイスに向いているビジネスは、集客力は弱いが、利益率が高い商品や独自商品商品など販売・仕入れ面で他社と圧倒的な差がある場合です。
        </p>
        <h2>自社EC型</h2>
        <p>
          自社EC型はオンラインストアを自身で管理することができます。日本で自社ECをつくるためのサービスは『BASE』・『カラーミーショップ』などがあります。
        </p>
        <h3>メリット</h3>
        <p>
          自社EC型は中間マージンがなく高い利益率を見込めるところです。そして自社でストアを管理するため制約などもほとんどなく、ストアのデザインも自由に行える範囲がひろく自分たちでブランドのイメージなどをコントロールしやすい点です。
        </p>
        <h3>デメリット</h3>
        <p>
          デメリットは、マーケットプレイスのように最初からたくさんお客様がいるわけではないので、自分たちで集客を行わなければなりません。
        </p>
        <h2>Shopifyのポジション</h2>
        Shopifyは自社EC型のサービスですが、マーケットプレイスにも同時に出店が連携できるオムニチャネルというポジションです。
        <StaticImage
          src="../../assets/images/Shopify解説-オムニチャンネル解説.001.jpeg"
          alt="オムニチャネル"
        />
        <h2>オムニチャネル</h2>
        <p>
          Shopifyの強みの一つがオムニチャネルです。Shopifyでは自社のオンラインストア作ることもできながら、登録商品をFacebook・Googleショッピング・楽天市場に出店しながらも、Shopifyのシステムで管理できる点です。『オムニ』はすべての、『チャネル』とは経路という意味があります。
        </p>
        <p>
          最大のメリットは複数のチャネル(販路)に出店しながらも、商品などの管理をShopifyで一元管理することができるので運営のコストがかかりにくいというところです。
        </p>
        <p>
          またGoogoleショッピングなどで集客し、自社のオンラインストアを覚えてもらいリピーターを獲得するという販路拡大も狙いやすい点もあります。
        </p>
      </section>

      <section>
        <h1>Shopifyの強み・選ばれる理由</h1>
        <h2>1完全ホスト型のECソリューション</h2>
        <p>
          マーチャントのファイルとデータは安全でクラウドに保存されています。
          クラウドにデータが保存されているので、いろいろな場所・コンピューターからアクセス管理できます。またローカルコンピューターのデータ破損の心配がありません。
          またShopifyではスマホアプリも用意されているため、スマホからも管理できます。
        </p>
        <h2>2 構築するのが低コストかつスピーディー</h2>
        <p>
          マーチャントがオンラインストアを始めるのに必要な機能はすべてShopify側により揃えられているので、より簡単にオンラインストアが構築できます。
          例えば、他のECカートサービスだとカード決済は別の会社と契約しなければならないとか、ストアのテーマなどを購入しなければならないとかありますが、基本的な機能は全て揃っています。
        </p>
        <h2>3 いつでも最適化できる。</h2>
        <p>
          Shopifyは売上が10万円未満から数十億円の企業までが利用しています。
          もしビジネスが成長しても同じようにシステムを使い続けることができます。
          アクセスの規模が増えても、そのままのスピードを維持しつづけることができます。
          実際、プラットフォームを移行するのは難しいです。商品情報・顧客情報の引き継ぎなどを行うのは労力がかかり思わぬトラブルが発生します。また移行期間中の機会損失などもありえます。
          Shopifyではプランのグレードはあれど、システムが変わることなくビジネスの成長に合わせて使い続けることができます。
        </p>
        <h2>4 ストアのメンテナンスがシンプル</h2>
        <p>
          ストアは完全にホストされているため、めんどうなSSL証明書・セキリティ更新などはすべてShopify側で行われるためマーチャントは自分のビジネス運営に専念することができます。
        </p>
        <h2>5 一般データ保護規則</h2>
        <p>
          Shopifyは越境EC(海外の顧客に向けてのオンライン販売)にも強いことがあげられます。
          一般データ保護規則(GDPR)
          ヨーロッパ顧客の個人データの収集及び取り扱い方法に準拠した機能がShopifyのプラットフォームに組み込まれています。
        </p>
        <h2>6 柔軟なソリューション Shopify</h2>
        <p>
          ではそれぞれのビジネスにあうようアプリが多数用意されています。それらはShopifyからだけでなくサードパーティからも開発されたものがあり5000以上のアプリが使用可能です。
          今現在は英語のものが多いですが、2018年にはShopiy
          Japan(日本法人)が発足してから日本語対応のものが日々増えてきています。
        </p>
        <p>
          たとえば、日本国内の配送・フルフィルメントサービスに対応したものや、オンラインストアに訪れた人とポップアップでチャットできる機能など様々です。
          こういった機能が標準でついている多機能なカートサービスもありますが、高価格になってきます。Shopifyでは自分のビジネスの必要に応じた機能を追加することにより、使わない機能へのコストを抑えることができるのも魅力の一つです。必要に応じた機能のみを入れることにより管理もシンプルになります。
        </p>
        <h2>7あらゆる場所で販売できる。 </h2>
        <p>
          Shopifyでは自社オンラインストアに限らず、
          Facebook・Googleショッピング・楽天市場などでShopifyで登録した商品を販売することを可能です。そのため、個別のプラットフォームごとに商品登録し直す必要もなく、Shopify上で一元管理することで在庫のミス・管理コストを減らすことができます。
        </p>
        <div>
          <StaticImage
            src="../../assets/images/マルチチャネルコーマスプラットフォーム.jpg"
            alt="shopifyマルチチャネル"
          />
          <StaticImage
            src="../../assets/images/202102Shopify__POS.jpg"
            alt="shopify-pos"
          />
        </div>
      </section>

      <section>
        <h1>Shopifyが使われているサイト一例</h1>
        <ShopifyUsersList />
      </section>
    </>
  )
}

export default AboutShopify
